import { Blog } from '@commerce/types/blog'
import { Layout } from '@components/common'
import commerce from '@lib/api/commerce'
import getGlobalStaticProps from '@lib/get-global-static-props'
import { createClient, getDocByUid } from '@lib/prismicio'
import { SliceZone } from '@prismicio/react'
import { PrismicDocument } from '@prismicio/types'
import type { GetStaticPropsContext } from 'next'
import { NextSeo } from 'next-seo'
import { components } from 'slices'

import { getStoreFrontUrl } from '@lib/helpers/general'
import { AnalyticsPageType } from '@shopify/hydrogen-react'

export async function getStaticProps({
  preview,
  previewData,
  locale,
  locales,
}: GetStaticPropsContext) {
  const client = createClient({ previewData })
  const globalProps = await getGlobalStaticProps(client, locale)

  const config = { locale, locales }

  const shopifyPagePromise = commerce.getPageByHandle({
    variables: { handle: 'index' },
    config,
    preview,
  })

  const prismicDocPromise = await getDocByUid(
    'landing-page',
    'index',
    client,
    locale
  )

  const { page: shopifyPage } = await shopifyPagePromise
  const prismicDoc: PrismicDocument | null = await prismicDocPromise

  if (!shopifyPage || !prismicDoc) {
    return {
      notFound: true,
    }
  }

  const { seo: metaValues } = shopifyPage

  const { blog } = await commerce.getBlogByHandle({
    variables: {
      handle: 'news',
    },
    config,
  })

  return {
    props: {
      ...globalProps,
      metaValues,
      prismicDoc,
      blog,
      analyticsPayload: {
        pageType: AnalyticsPageType.home,
      },
    },
    revalidate: 60,
  }
}

type PageProps = {
  metaValues: {
    title: string
    description: string
  }
  prismicDoc: PrismicDocument | null
  blog: Blog
}

export default function Home({ metaValues, prismicDoc, blog }: PageProps) {
  const { description, title } = metaValues
  const articles = blog?.articles.slice(-4)?.reverse()
  const strorefrontUrl = getStoreFrontUrl()

  return (
    <>
      {/* breadcrumb schema code */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": "1",
                "name": "Home",
                "item": "${strorefrontUrl}/"
              }
            ]
          }`,
        }}
      />
      {/* Organization schema code */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Hoskings",
            "alternateName": "Hoskings Jewellers",
            "url": "https://hoskings.com.au/",
            "logo":"https://cdn.shopify.com/s/files/1/0184/1161/8368/files/hoskings-logo.svg",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "1800 819 796",
              "contactType": "sales",
              "areaServed": "AU",
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/HoskingsJewellers/",
              "https://www.instagram.com/hoskingsjewellers/"
            ]
          }`,
        }}
      />
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          type: 'website',
          title: title,
          description: description,
        }}
      />
      <SliceZone
        slices={prismicDoc?.data.slices}
        components={components}
        context={{ articles }}
      />
    </>
  )
}

Home.Layout = Layout
